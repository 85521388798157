import React, { forwardRef } from "react";
import { StyledText, StyledTextProps } from "./Text.styles";

export interface TextProps extends StyledTextProps {
  value?: string;
}

type TextRef = ((instance: HTMLSpanElement | null) => void) | React.RefObject<HTMLSpanElement> | null | undefined;

/**
 * `component` prop is the actual DOM Element,
 * `variant` prop is for the font size / style
 *
 * <Text component="p" variant="body1" />
 *
 * PS: `forwardRef` is to fix a bug related to `emotion` + `material-ui`
 *     where the ref was not being properly handled.
 * */
export const Text = forwardRef((props: TextProps, ref: TextRef) => {
  const { value, component = "span", variant, color = "textPrimary", weight, textTransform, children, ...rest } = props;
  // Don't translate value without truthy check to prevent
  // 'undefined' or 'null' being rendered as Text
  const valueProp = value;
  const message = (children || valueProp) as string;

  return (
    <StyledText
      component={component}
      variant={variant}
      weight={weight}
      color={color}
      textTransform={textTransform}
      ref={ref}
      {...rest}
    >
      {message}
    </StyledText>
  );
});

Text.displayName = "Text";
