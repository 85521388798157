import React from "react";
import { Stack, Typography, Divider } from "@mui/material";
import { useTheme } from "@cbex/utils/theme";

export interface InputTextProps {
  label: string;
  body: string;
  width: number;
}

export const InputText = (props: InputTextProps) => {
  const { body, label, width } = props;
  const theme = useTheme();
  return (
    <Stack id="InputText" mb={1} width={width}>
      <Typography variant={"caption"} color={theme.appColors.fontColour}>
        {label}
      </Typography>
      <Typography variant={"body1"} color={theme.appColors.contentBackground}>
        {body}
      </Typography>
      <Divider
        orientation="horizontal"
        light
        sx={{
          backgroundColor: theme.appColors.contentHover,
        }}
      />
    </Stack>
  );
};

export default InputText;
